@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
    margin         : 0;
    padding        : 0;
    box-sizing     : border-box;
    text-decoration: none !important;
    list-style     : none;
    font-family    : "Raleway", sans-serif;
    color          : #fff;

    &::selection {
        background: #000;
        color     : #fff;
    }
}


body {
    display   : grid;
    min-height: 100vh;
}

.cust-btn {
    padding    : 0.5rem;
    background : white;
    color      : #000;
    border     : .1rem solid black;
    font-size  : 1.3rem;
    font-weight: 700;
    transition : .5s;
    cursor     : pointer;


    &:hover {
        border    : .1rem solid #fff;
        background: #000;
        color     : #fff;
    }

    &.rounded {
        border-radius: .5rem;
    }
}

header {
    width              : 100%;
    background         : url(../public/images/header_bg_4.jpg);
    background-size    : 100%;
    background-position: center center;
    background-repeat  : no-repeat;
    margin-top         : 5.1rem;

    nav {
        display        : flex;
        position       : fixed;
        width          : 100%;
        top            : 0;
        justify-content: space-between;
        align-items    : center;
        padding        : 1rem;
        border-bottom  : 0.1rem solid #202020;
        z-index        : 10000;
        // &.black {
        background     : #000;
        // }

        @media screen and (min-width: 840px) {
            padding: 1rem 3rem;
        }

        .nav-logo {
            img {
                width: 10rem;
            }
        }

        ul {
            align-items    : center;
            justify-content: center;
            gap            : 1rem;
            display        : flex;

            @media screen and (max-width: 840px) {
                flex-direction: column;
                position      : fixed;
                top           : 0;
                width         : 100%;
                padding       : 1rem;
                left          : 0;
                background    : #fff;
                transition    : .5s;
                transform     : translateY(-200%);

                &.show {
                    transform: translateY(0);
                }

                .nav-toggler {
                    background   : none;
                    border       : .1rem solid #000;
                    border-radius: .5rem;
                    padding      : 0.5rem;
                    cursor       : pointer;
                    font-weight  : bolder;
                    color        : #000;
                    justify-self : end;
                    align-self   : end;

                    @media screen and (min-width: 840px) {
                        display: none;
                    }
                }
            }

            @media screen and (min-width: 840px) {
                display: flex;
            }

            li {

                @media screen and (max-width: 840px) {
                    width  : 100%;
                    display: block;
                }

                a {
                    color      : #fff;
                    font-size  : .9rem;
                    font-weight: 700;
                    transition : .5s;

                    @media screen and (max-width: 840px) {
                        color     : black;
                        display   : block;
                        width     : 100%;
                        padding   : 1rem;
                        text-align: center;
                    }

                    &:hover {
                        color: #c0c0c0;
                    }
                }
            }
        }

        .nav-toggler {
            background   : none;
            border       : .1rem solid #fff;
            border-radius: .5rem;
            padding      : 0.5rem;
            cursor       : pointer;
            font-weight  : bolder;

            @media screen and (min-width: 840px) {
                display: none;
            }
        }
    }

    .hero {
        display        : flex;
        justify-content: space-between;
        align-items    : center;
        padding        : 1rem;

        @media screen and (min-width: 840px) {
            padding: 5rem 3rem;
        }

        div {

            @media screen and (max-width: 840px) {
                width  : 100%;
                display: grid;

                button {
                    place-self: center;
                }
            }

            button {
                margin-top: 2rem;
            }

        }

        h1 {
            font-weight: 900;
            font-size  : 3.3rem;
            width      : 100%;
            max-width  : 32rem;
            text-align : center;
            margin     : auto !important;

            @media screen and (min-width: 840px) {
                margin    : unset;
                text-align: left;
            }

            p {
                line-height: 1.4rem;
                font-size  : 1rem;
            }
        }

        img {
            width  : 20rem;
            display: none;

            @media screen and (min-width: 840px) {
                margin-right: 6rem;
                display     : block;
            }


        }
    }
}

h2 {
    color     : #000;
    text-align: center;
    font-size : 2rem;
    margin-top: 3rem;
}

#courseName {
    font-size : 2.4rem;
    position  : relative;
    width     : fit-content;
    margin    : auto;
    margin-top: 2rem;

    &::after {
        content      : '';
        width        : 100%;
        height       : 0.2rem;
        background   : #202020;
        position     : absolute;
        bottom       : -.4rem;
        left         : 0;
        border-radius: 50%;
    }
}

#cards {
    display        : flex;
    justify-content: center;
    align-items    : center;
    gap            : 1.5rem;
    flex-wrap      : wrap;
    padding        : 3rem;

    @media screen and (max-width: 840px) {
        padding: .5rem;
    }

    .card {
        display      : grid;
        width        : 18rem;
        height       : 20rem;
        background   : #202020;
        color        : #000;
        cursor       : pointer;
        border-radius: 1rem 0 1rem 0;
        overflow     : hidden;
        box-shadow   : 0 0 2rem .5rem #0000004d;
        transition   : .5s;
        border       : none;

        &:hover {
            transform : translateY(-5%);
            box-shadow: none;
        }

        .card-image {
            width : 100%;
            height: 100%;

            img {
                width     : 100%;
                height    : 100%;
                object-fit: cover;
            }
        }

        .card-text {
            padding-inline : 3rem;
            display        : flex;
            justify-content: space-between;
            align-items    : center;

            font-size  : 1.2rem;
            font-weight: bolder;
        }
    }
}

label,
input {
    color: black;
}

.reqs {
    padding        : 1rem;
    display        : flex;
    justify-content: space-between;
    align-content  : center;
    flex-direction : row-reverse;
    align-items    : center;
    flex-wrap      : wrap;
    gap            : 1.5rem;

    @media screen and (min-width: 840px) {
        padding: 3rem;
    }

    .wrapper {
        width        : 100%;
        max-width    : 30rem;
        text-align   : left;
        height       : 100%;
        padding      : 2rem 1.5rem;
        border-radius: .5rem;
        box-shadow   : 0 0 1rem .3rem #0000004d;
        margin       : auto;
        transition   : .5s;

        p,
        h3,
        h4,
        h5,
        span {
            color: #000;
        }

        h3 {
            margin-top   : .3rem;
            margin-bottom: 0;

        }

        p {
            margin-left: 1rem;
        }

        &:hover {
            background: #202020;

            p,
            h3,
            h5,
            h4,
            span {
                color: #fff;
            }
        }

        button {
            float     : right;
            margin-top: 1rem;
        }
    }

    img {
        width        : 100%;
        max-width    : 35rem;
        max-height   : 35rem;
        border-radius: 1rem;
        margin       : auto;
    }
}

.course-details {
    padding        : 1rem;
    display        : flex;
    justify-content: space-between;
    align-content  : center;
    align-items    : center;
    flex-wrap      : wrap;
    gap            : 1.5rem;

    @media screen and (min-width: 840px) {
        padding: 3rem;
    }

    .text {
        color        : #000;
        width        : 100%;
        max-width    : 30rem;
        text-align   : justify;
        height       : 100%;
        padding      : 2rem 1.5rem;
        border-radius: .5rem;
        box-shadow   : 0 0 1rem .3rem #0000004d;
        margin       : auto;
        transition   : .5s;

        &:hover {
            background: #202020;
            color     : #fff;
        }

        button {
            float     : right;
            margin-top: 1rem;
        }
    }

    .details-image {
        width        : 100%;
        max-width    : 35rem;
        max-height   : 35rem;
        border-radius: 1rem;
        margin       : auto;
    }
}

#registerForm {
    width        : 90%;
    max-width    : 28rem;
    margin       : 2rem auto;
    background   : white;
    padding      : .5rem 1.5rem;
    border-radius: .5rem;
    box-shadow   : 0 0 2rem .3rem #00000034;

    h2 {
        font-size    : clamp(1.4rem, 3vw, 1.8rem);
        color        : black;
        font-weight  : bolder;
        font-style   : italic;
        margin-bottom: 1rem;
    }

    .form-floating {
        display        : flex;
        justify-content: center;
        align-items    : center;
        background     : white;
        border         : .1rem groove black;
        border-radius  : .5rem;

        input {
            border-radius: .5rem;
            box-shadow   : none;
            border       : none;
        }
    }

    .btn-primary {
        width        : 100%;
        background   : black;
        box-shadow   : 0 .1rem .2rem #00000034;
        border       : .1rem solid black;
        margin-bottom: 1rem;
        transition   : .5s;

        &:hover {
            box-shadow: none;
        }
    }

    .acts {
        display: flex;
        gap    : 1rem;
    }

    .step2,
    .step3 {
        display: none;
    }

    .gen {
        display        : flex;
        gap            : 1rem;
        justify-content: space-between;

        .gender {
            display: flex;
            gap    : .5rem;
        }
    }
}

footer {
    padding        : 0.8rem 3rem;
    background     : #000;
    display        : flex;
    // place-items: center;
    flex-wrap      : wrap;
    justify-content: center;
    align-items    : center;
    gap            : 1rem;
    color          : #fff;

    @media screen and (min-width: 760px) {
        justify-content: space-between;
    }

    span {
        border-bottom: 1px solid #fff;
        margin-bottom: 0.5rem;
    }

    .socials {
        display        : flex;
        justify-content: center;
        align-items    : center;
        gap            : 2rem;

        a {
            img {
                transition: .5s;
            }

            &:hover {
                img {
                    transform: scale(.7);
                }
            }
        }
    }

    .contact {
        display    : grid;
        place-items: center;
        gap        : 0.2rem;


        a {
            color         : white;
            transition    : .5s;
            letter-spacing: 1px;

            &:hover {
                letter-spacing: 0;
            }

        }
    }
}